import React, { useState, useEffect, useReducer } from "react";
import "./speakersHome.scss";
import { Row, Col } from "react-bootstrap";
import "swiper/scss";
import { AiFillCloseCircle } from "react-icons/ai";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Link, useParams } from "react-router-dom";
import { Navigation } from "swiper";
import Modal from "react-modal";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { TiArrowBack, TiArrowForward } from "react-icons/ti";
import { SpeakersModal } from "../../../../shared/speakerModal/SpeakerModal";
import { useTranslation } from "react-i18next";
import {
  listingInitialState,
  listingReducer,
} from "../../../../shared/reducers/listingReducer";
import axios from "../../../../../config/axios";

const Speakers = () => {
  const swiper = useSwiper();
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  const { slug, url } = useParams();
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));
 

      listingDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`activities/speakers/${slug}`); //
        listingDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data.items,
        });

      } catch (error) {
        listingDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    
  };
  useEffect(() => {
    loadItems();
  }, []);
  return (
    <>
      {listingState.status !== "fetching" && (
        <>
          {listingState.error &&
            listingState.status === "fetchFailed" ? null : (
            <div className="paddingContainerSpeaker">
              {listingState.data.length > 0 && (
                <div
                  className={`contentContainerSpeaker ${i18n.language === "ar" ? "arabic" : "english"
                    }`}
                >
                  <h2 className="heading_text">{items.menuSpeakersText}</h2>
                  <div className="containerBackupSpeakers">
                    <Swiper
                      className=" "
                      //   navigation={length > 4 ? true : false}
                      navigation={{
                        nextEl: ".review-swiper-button-prev",
                        prevEl: ".review-swiper-button-next",
                      }}
                      modules={[Navigation]}
                      spaceBetween={35}
                      breakpoints={{
                        300: {
                          slidesPerView: 1,
                          width: 300,
                        },
                        450: {
                          slidesPerView: 1,
                          width: 350,
                        },
                        600: {
                          slidesPerView: 1,
                          width: 434,
                        },
                        768: {
                          slidesPerView: 2,
                          width: 640,
                        },
                        992: {
                          slidesPerView: 3,
                        },

                        1025: {
                          slidesPerView: 4,
                          spaceBetween: 39,
                        },
                      }}
                    >
                      {listingState &&
                        !listingState.isFetching &&
                        listingState.status === "fetched" &&
                        listingState.data && (
                          <>
                            {listingState.data.map((speakers, index) => (
                              <SwiperSlide key={index}>
                                <SpeakersModal
                                  effects="no"
                                  speaker={speakers}
                                />
                              </SwiperSlide>
                            ))}
                          </>
                        )}
                      {listingState.data.length > 4 && <>

                        <TiArrowBack className="review-swiper-button-prev" />
                        <TiArrowForward className="review-swiper-button-next" />
                      </>}
                    </Swiper>

                    <Link className="viewAll_textHolder mt-0" to={slug != undefined && url != undefined ? `/speakers/${slug}/${url}` : slug !== undefined ? `/speakers/${slug}` : "/speakers"}>
                      <p
                        className="viewAll mb-0"
                        title={
                          i18n.language === "ar"
                            ? "شاهد المزيد من المتحدثين"
                            : "See more speakers"
                        }
                      >
                        {items.linkViewAllText}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14.926"
                            height="13"
                            viewBox="0 0 14.926 13"
                          >
                            <g
                              id="arrow"
                              transform="translate(-1961.224 -1924)"
                            >
                              <g id="Group_102" data-name="Group 102">
                                <path
                                  id="Path_166"
                                  data-name="Path 166"
                                  d="M1973.4,1931.008h-10.887c-.219,0-.493.073-.646-.03-.27-.183-.64-.473-.643-.721,0-.231.394-.511.669-.681.16-.1.427-.026.647-.026h11.024c-.247-.286-.382-.462-.537-.617q-1.729-1.735-3.466-3.464c-.376-.374-.681-.784-.193-1.245s.877-.145,1.253.231q2.585,2.586,5.185,5.156a.791.791,0,0,1,.051,1.266q-2.9,2.892-5.82,5.761c-.165.163-.4.369-.595.362-.249-.009-.649-.17-.7-.346a1.1,1.1,0,0,1,.169-.908c.891-.967,1.839-1.882,2.775-2.808.589-.582,1.193-1.149,1.79-1.722Z"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </p>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Speakers;
