import "./App.scss";
import React from "react";
import AppRoutes from "./config/appRoutes";
function App() {
  
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
