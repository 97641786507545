import React, { useEffect, useReducer, useState } from "react";
import BodyLayout from "../../layout/BodyLayout";
import { useTranslation } from "react-i18next";
import "./home.scss";
import Agenda from "./homePartials/agenda/AgendaHome";
import Speakers from "./homePartials/speakers/SpeakersHome";
import Sponsors from "./homePartials/sponsorPartner/SponsorPartner";
import { listingInitialState, listingReducer } from "../../shared/reducers/listingReducer";
import axios from "../../../config/axios";
import { countDaysWithStatus1, featureAllowed, removeAndCapitalizeEachWord } from "../../../Helper/featuresHelper";
import { ContainerLoading } from "../../shared/loading/Loading";
import { useParams } from "react-router-dom";
import ErrorPage from "../error/ErrorPage";

const Home = () => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true)
  const [activity, setActivity] = useState({})
  const content = JSON.parse(localStorage.getItem("content"));
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const { slug, url } = useParams();
  useEffect(() => {
    document.title = removeAndCapitalizeEachWord(slug)
  }, [slug]);
  const loadItems = async () => {
    const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));

    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`activities/content/${slug}`); //
      sessionStorage.setItem("content", JSON.stringify(res.data));
      //localStorage.setItem("currentActivity", JSON.stringify({ clientId: res.data.clientId, activityId: res.data._id, slug: slug }));
      localStorage.setItem("content", JSON.stringify(res.data));

      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setActivity(res.data);

      //localStorage.setItem("currentActivity", JSON.stringify({ clientId: process.env.REACT_APP_CLIENT_ID, activityId: process.env.REACT_APP_ACTIVITY_ID, slug: lastWord }));
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      //localStorage.removeItem("content")
    }

  };

  useEffect(() => {
    localStorage.removeItem(`activeAgendaDay${slug}`);
    loadItems();
  }, [slug]);

  const clientFeatureAllowed = (feature) => {
    const content = JSON.parse(localStorage.getItem("content"));
    if (feature) {
      if (
        content &&
        content.activity && content.activity.client &&
        content.activity.client.packageFeatures &&
        content.activity.client.packageFeatures[feature] === "1"
      ) {

        return true;
      }
    }

    return false

  };
  useEffect(() => {
    if (content != null) {
      setTimeout(() => {
        setLoading(false)
      }, 200);

    }
  }, [content]);

  return (
    <>
      {listingState.isLoading ? <>
        <div className="loadingContainer">
          <ContainerLoading />
        </div></> : listingState.status === "fetchFailed" ? <ErrorPage slug={slug} url={url} /> :
        <BodyLayout>
          <div
            className={`homeContent ${i18n.language === "ar" ? "arabic" : ""}`}
          >

            {content.activity && content.activity.allowedFeatures && <>
              {featureAllowed("showAgendaOnHomePage") && clientFeatureAllowed("agenda") &&
                <Agenda />}
              {featureAllowed("showSpeakersOnHomePage") && clientFeatureAllowed("speakers") &&
                <Speakers />}
              {featureAllowed("showSponsorsPartnersOnHomePage") && (clientFeatureAllowed("sponsors") || clientFeatureAllowed("partners")) &&
                <Sponsors />}
            </>}

          </div>
        </BodyLayout>
      }
    </>
  );
};

export default Home;
