import React, { useState, useEffect, useReducer } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import "../../speakers/speakerPage.scss";
import { IoMdCloseCircle } from "react-icons/io";
import AgendaForSpeaker from "./AgendaForSpeaker";
import { HiUser } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { PiMicrophoneStageFill } from "react-icons/pi";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import {
  listingInitialState,
  listingReducer,
} from "../../../shared/reducers/listingReducer";
import axios from "../../../../config/axios";
import dummy from "../../../../assets/images/dummy-speaker.png"
import { featureAllowed, generalFeatureAllowed } from "../../../../Helper/featuresHelper";
import { useParams } from "react-router-dom";
function MyVerticallyCenteredModal(props) {
  const { i18n } = useTranslation();
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={i18n.language === "ar" ? "arabic" : ""}
    >
      <>


        {props.show && props.speakers && props.speakers.map((speakers, index) => {
          if (props.id === speakers.speakerId)
            return (
              <div
                className={`modalDiv ${i18n.language === "ar" ? "arabic" : ""}`}
              >

                <Modal.Header className="bg-tertiaryClient ">
                  <Modal.Title id="contained-modal-title-vcenter">
                    <h4 className=" mb-0 text-primaryWhite modalTitle fontRegular">
                      {" "}
                      {screen.items && screen.items.modalSpeakerDetailsText}
                    </h4>
                  </Modal.Title>
                  <div>
                    <IoMdCloseCircle
                      onClick={() => props.onHide()}
                      className="closeIcon"
                    />
                  </div>
                </Modal.Header>
                <Modal.Body className="p-0">
                  <Row className=" px-4">

                    <Col
                      xl={11} lg={11} md={10}
                      className="speakerStyleDisplay my-xl-3 my-lg-3 my-md-3"
                    >
                      <div
                        className={
                          i18n.language === "ar"
                            ? "d-flex align-item-center justify-content-center modalSpeakerImage mt-3 ms-4"
                            : "d-flex align-item-center justify-content-center modalSpeakerImage mt-3 me-xl-4 me-lg-4 me-md-4"
                        }
                      >
                        <img
                          src={speakers.photo ? process.env.REACT_APP_BACKEND_URL + "public/" + speakers.photo : dummy}
                          width="150"
                          height="150"

                        />
                      </div>
                      <div className="d-flex align-item-center justify-content-center flex-column mt-3">
                        <h4 className="speaker_name noHeight">
                          {i18n.language === "ar"
                            ? speakers.firstNameArabic +
                            " " +
                            speakers.lastNameArabic
                            : speakers.firstName + " " + speakers.lastName}
                        </h4>
                        <h6 className="text-secondaryClient italic mb-1">
                          {i18n.language === "ar"
                            ? speakers.companyArabic
                            : speakers.company}
                          {speakers.company && speakers.designation && ", "}
                          {i18n.language === "ar"
                            ? speakers.designationArabic
                            : speakers.designation}
                        </h6>
                        {speakers.country &&
                          <h6 className="text-tertiaryClient fs-8 mb-0">
                            {i18n.language === "ar"
                              ? speakers.countryArabic
                              : speakers.country}
                          </h6>}
                        {/* {speakers.nationality &&
                  <h6 className="text-quaternaryClient fs-8 mb-0">
                    {i18n.language === "ar"
                      ? speakers.nationalityArabic
                      : speakers.nationality}
                  </h6>} */}
                        {screen.content && screen.content.activity && screen.content.activity.speakerSettings && <>

                          {screen.content.activity.speakerSettings && screen.content.activity.speakerSettings.showEmail === "1" && speakers.email &&
                            <a href={`mailto:${speakers.email}`} className="mb-1  emailSpeaker">
                              {i18n.language === "ar" ? (
                                <>{speakers.email}</>
                              ) : (
                                <>
                                  {speakers.email}</>
                              )}
                            </a>}
                          {screen.content.activity.speakerSettings && screen.content.activity.speakerSettings.showPhone === "1" && speakers.phone &&
                            <a href={`tel:${speakers.phone}`} className="mb-1  phoneSpeaker">
                              {i18n.language === "ar" ? (
                                <>{speakers.phone}</>
                              ) : (
                                <>{speakers.phone}</>
                              )}
                            </a>}
                        </>}
                        <p
                          className="fs-8 text-primaryGray html mt-1"
                          dangerouslySetInnerHTML={{
                            __html:
                              i18n.language === "ar"
                                ? speakers.introductionArabic
                                : speakers.introduction,
                          }}
                        ></p>
                      </div>
                    </Col>
                    <Col className="speakerSocialChannel">
                      <div className="speaker_img_social" >
                        {speakers.facebookProfile && <a className=" icon" href={speakers.facebookProfile} title="View facebook"><FaFacebook className=" icon" /></a>}
                        {speakers.instagramProfile && <a className=" icon" href={speakers.instagramProfile} title="View instagram"><FaInstagram className=" icon" /></a>}
                        {speakers.linkedinProfile && <a className=" icon" href={speakers.linkedinProfile} title="View linkedin"><FaLinkedin className=" icon" /></a>}
                        {speakers.twitterProfile && <a className=" icon" href={speakers.twitterProfile} title="View twitter"><FaSquareXTwitter className=" icon" /></a>}
                        {speakers.youtubeProfile && <a className=" icon" href={speakers.youtubeProfile} title="View youtube"><FaYoutube className=" icon" /></a>}



                      </div>
                    </Col>


                  </Row>
                

                  {generalFeatureAllowed("agenda") &&
                    <AgendaForSpeaker id={speakers.id} sessions={speakers.speakerSessions} />}
                </Modal.Body>
              </div>
            );
        })}
      </>
    </Modal>
  );
}

const AgendaSpeakerModal = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { i18n } = useTranslation();
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  const { slug, url } = useParams();
  const [speakersState, speakersDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadSpeakers = async () => {
    const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));


    speakersDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`activities/speakers/${slug}`); //
      speakersDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

    } catch (error) {
      speakersDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }

  };
  useEffect(() => {
    if (modalShow) {
      loadSpeakers();
    }

  }, [modalShow]);
  return (
    <>
      {speakersState.error && speakersState.status === "fetchFailed" ? null : (
        <>
          {props.moderator ? <div className={`agendaSpeaker moderator`}
            title={items.tooltipSpeakerDetails}
            onClick={() => setModalShow(true)}>
            <PiMicrophoneStageFill className="icon" />{" "}

            {i18n.language === "ar" ? (
              <>
                {props.firstNameArabic} {props.lastNameArabic}{" "}
              </>
            ) : (
              <>
                {props.firstName} {props.lastName}
              </>
            )} (Moderator)
          </div> :
            <div
              className={`agendaSpeaker `}
              title={items.tooltipSpeakerDetails}
              onClick={() => setModalShow(true)}
            >
              <HiUser className="icon" />{" "}
              {i18n.language === "ar" ? (
                <>
                  {props.firstNameArabic} {props.lastNameArabic}{" "}
                </>
              ) : (
                <>
                  {props.firstName} {props.lastName}
                </>
              )}
            </div>}
          <MyVerticallyCenteredModal
            show={modalShow}
            id={props.id}

            speakers={speakersState.data && speakersState.data.items}
            // firstName={props.firstName}
            onHide={() => setModalShow(false)}
          />
        </>
      )}
    </>
  );
};

export default AgendaSpeakerModal;
