import React, { useState, useEffect, useReducer } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import "../speakerPage.scss";
import "../../../../App.scss"
import dummy from "../../../../assets/images/dummy-speaker.png";
import AgendaForSpeaker from "../../agenda/agendaPartials/AgendaForSpeaker";
import { useTranslation } from "react-i18next";
import {
  listingInitialState,
  listingReducer,
} from "../../../shared/reducers/listingReducer";
import axios from "../../../../config/axios";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { featureAllowed, generalFeatureAllowed } from "../../../../Helper/featuresHelper";
function MyVerticallyCenteredModal(speaker) {
  const { t, i18n } = useTranslation();
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);
  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`event_speaker`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  useEffect(() => {
    //loadItems();
  }, []);
  return (
    <Modal
      {...speaker}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <div className={
        i18n.language === "ar" ? "modalDiv arabic" : "modalDiv"
      }>
        <Modal.Header className="bg-tertiaryClient">
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="text-primaryWhite modalTitle mb-0">
              {screen.items && screen.items.modalSpeakerDetailsText}
            </h4>
          </Modal.Title>
          <div>
            <IoMdCloseCircle
              onClick={() => speaker.onHide()}
              className="closeIcon"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Row className=" px-4">
            <Col
              xl={11} lg={11} md={10}
              className="speakerStyleDisplay my-3"
            >
              <div
                className={
                  i18n.language === "ar"
                    ? "d-flex align-item-center justify-content-center modalSpeakerImage mt-3 ms-4"
                    : "d-flex align-item-center justify-content-center modalSpeakerImage mt-3 me-xl-4 me-lg-4 me-md-4"
                }
              >
                <img
                  src={speaker.speaker.photo ? process.env.REACT_APP_BACKEND_URL + "public/" + speaker.speaker.photo : dummy}
                  width="150"
                  height="150"
                  onError={handleOnError}
                />
              </div>
              <div className="d-flex align-item-center justify-content-center flex-column mt-3">
                <h4 className="speaker_name noHeight">
                  {i18n.language === "ar"
                    ? speaker.speaker.firstNameArabic +
                    " " +
                    speaker.speaker.lastNameArabic
                    : speaker.speaker.firstName + " " + speaker.speaker.lastName}
                </h4>
                <h6 className="text-secondaryClient italic mb-1">
                  {i18n.language === "ar"
                    ? speaker.speaker.companyArabic
                    : speaker.speaker.company}
                  {speaker.speaker.company && speaker.speaker.designation && ", "}
                  {i18n.language === "ar"
                    ? speaker.speaker.designationArabic
                    : speaker.speaker.designation}
                </h6>
                {speaker.speaker.country &&
                  <h6 className="text-tertiaryClient fs-8 mb-0">
                    {i18n.language === "ar"
                      ? speaker.speaker.countryArabic
                      : speaker.speaker.country}
                  </h6>}
                {/* {speaker.speaker.nationality &&
                  <h6 className="text-quaternaryClient fs-8 mb-0">
                    {i18n.language === "ar"
                      ? speaker.speaker.nationalityArabic
                      : speaker.speaker.nationality}
                  </h6>} */}
                {screen.content && screen.content.activity && screen.content.activity.speakerSettings && <>

                  {screen.content.activity.speakerSettings && screen.content.activity.speakerSettings.showEmail === "1" && speaker.speaker.email &&
                    <a href={`mailto:${speaker.speaker.email}`} className="mb-1  emailSpeaker">
                      {i18n.language === "ar" ? (
                        <>{speaker.speaker.email}</>
                      ) : (
                        <>
                          {speaker.speaker.email}</>
                      )}
                    </a>}
                  {screen.content.activity.speakerSettings && screen.content.activity.speakerSettings.showPhone === "1" && speaker.speaker.phone &&
                    <a href={`tel:${speaker.speaker.phone}`} className="mb-1  phoneSpeaker">
                      {i18n.language === "ar" ? (
                        <>{speaker.speaker.phone}</>
                      ) : (
                        <>{speaker.speaker.phone}</>
                      )}
                    </a>}
                </>}
                <p
                  className="fs-8 text-primaryGray html mt-1"
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "ar"
                        ? speaker.speaker.introductionArabic
                        : speaker.speaker.introduction,
                  }}
                ></p>
              </div>
            </Col>
            <Col className="speakerSocialChannel">
              <div className="speaker_img_social" >
                {speaker.speaker.facebookProfile && <a className=" icon" href={speaker.speaker.facebookProfile} title="View facebook"><FaFacebook className=" icon" /></a>}
                {speaker.speaker.instagramProfile && <a className=" icon" href={speaker.speaker.instagramProfile} title="View instagram"><FaInstagram className=" icon" /></a>}
                {speaker.speaker.linkedinProfile && <a className=" icon" href={speaker.speaker.linkedinProfile} title="View linkedin"><FaLinkedin className=" icon" /></a>}
                {speaker.speaker.twitterProfile && <a className=" icon" href={speaker.speaker.twitterProfile} title="View twitter"><FaSquareXTwitter className=" icon" /></a>}
                {speaker.speaker.youtubeProfile && <a className=" icon" href={speaker.speaker.youtubeProfile} title="View youtube"><FaYoutube className=" icon" /></a>}



              </div>
            </Col>
          </Row>
         
          {generalFeatureAllowed("agenda") &&
            <AgendaForSpeaker id={speaker.speaker._id} sessions={speaker.speaker.speakerSessions} />
          }
        </Modal.Body>
      </div>


    </Modal >
  );
}
export const SpeakerLayoutList = ({ speaker }) => {
  //const [modalShow, setModalShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [screen, setScreen] = useState({
    items: {},
    content: {},
    modalShow: false,
  })
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);
  return (
    <>

      <Col
        //title={items.tooltipMoreDetails}
        onClick={() => setScreen((prevState) => ({
          ...prevState,
          modalShow: true,
        }))}
        title="Click to view full details"
        className="mb-2 listLayoutSeperation p-0 mx-auto cursor-pointer"
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        {/* <div className="listSpeakersLayout mt-2">
          <div className="speaker_img mx-auto">
            <img
              className="mx-auto"
              // src={`https://ahead.events/aep/uploads/images/${speaker.image}`}
              src={speaker.photo ? process.env.REACT_APP_BACKEND_URL + "public/" + speaker.photo : dummy}
              alt={speaker.firstName}
              loading="lazy"
            />
          </div>
          <div className="details">
            <h6 className="speaker_name">
              {i18n.language === "ar"
                ? speaker.firstNameArabic + " " + speaker.lastNameArabic
                : speaker.firstName + " " + speaker.lastName}
            </h6>
            <p className="speaker_designation mb-1 text-secondaryClient italic">
              {i18n.language === "ar" ? speaker.companyArabic : speaker.company}
            </p>
            <p className="speaker_designation fs-7 mb-1">
              {" "}
              {i18n.language === "ar"
                ? speaker.designationArabic
                : speaker.designation}
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "ar"
                    ? speaker.introductionArabic
                    : speaker.introduction,
              }}
              className="fs-8 mb-1 html text-primaryGray"
            ></p>
          </div>
        </div> */}
        <div>
          <Row className=" px-4">

            <Col
              xl={11} lg={10} md={10}
              className="speakerStyleDisplay my-3"
            >
              <div
                className={
                  i18n.language === "ar"
                    ? "d-flex align-item-center justify-content-center modalSpeakerImageRound mt-3 ms-4"
                    : "d-flex align-item-center justify-content-center modalSpeakerImageRound mt-3 me-xl-4 me-lg-4 me-md-4"
                }
              >
                <img
                  className="mx-auto"
                  // src={`https://ahead.events/aep/uploads/images/${speaker.image}`}
                  src={speaker.photo ? process.env.REACT_APP_BACKEND_URL + "public/" + speaker.photo : dummy}
                  width="140"
                  height="140"
                  alt={speaker.firstName}
                  loading="lazy"
                />
              </div>
              <div className="d-flex align-item-center justify-content-center flex-column mt-3">
                <h4 className="speaker_name noHeight">
                  {i18n.language === "ar"
                    ? speaker.firstNameArabic +
                    " " +
                    speaker.lastNameArabic
                    : speaker.firstName + " " + speaker.lastName}
                </h4>
                {speaker.company || speaker.designation ?
                  <h6 className="text-secondaryClient italic">
                    {i18n.language === "ar"
                      ? speaker.companyArabic
                      : speaker.company}{speaker.designation && speaker.company && ", "}


                    {i18n.language === "ar"
                      ? speaker.designationArabic
                      : speaker.designation}
                  </h6> : null}
                {/* {speaker.country &&
                  <h6 className="text-tertiaryClient fs-8">
                    {i18n.language === "ar"
                      ? speaker.countryArabic
                      : speaker.country}
                  </h6>}
                {speaker.nationality &&
                  <h6 className="text-quaternaryClient fs-8">
                    {i18n.language === "ar"
                      ? speaker.nationalityArabic
                      : speaker.nationality}
                  </h6>}
                {screen.content && screen.content.activity && screen.content.activity.speakerSettings && <>

                  {screen.content.activity.speakerSettings && screen.content.activity.speakerSettings.showEmail === "1" && speaker.email &&
                    <a href={`mailto:${speaker.email}`} className="mb-1  emailSpeaker">
                      {i18n.language === "ar" ? (
                        <>{speaker.email}</>
                      ) : (
                        <>{speaker.email}</>
                      )}
                    </a>}
                  {screen.content.activity.speakerSettings && screen.content.activity.speakerSettings.showPhone === "1" && speaker.phone &&
                    <a href={`tel:${speaker.phone}`} className="mb-1  phoneSpeaker">
                      {i18n.language === "ar" ? (
                        <>{speaker.phone}</>
                      ) : (
                        <>{speaker.phone}</>
                      )}
                    </a>}
                </>} */}
                {speaker.introduction &&
                  <p
                    className="fs-8 text-primaryGray html mt-1"
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === "ar"
                          ? speaker.introductionArabic
                          : speaker.introduction,
                    }}
                  ></p>}
              </div>
            </Col>
            {/* <Col className="speakerSocialChannel">
              <div className="speaker_img_social " >
                {speaker.facebookProfile && <a className=" icon" href={speaker.facebookProfile} ><FaFacebook className=" icon" /></a>}
                {speaker.instagramProfile && <a className=" icon" href={speaker.instagramProfile}><FaInstagram className=" icon" /></a>}
                {speaker.linkedinProfile && <a className=" icon" href={speaker.linkedinProfile}><FaLinkedin className=" icon" /></a>}
                {speaker.twitterProfile && <a className=" icon" href={speaker.twitterProfile}><FaSquareXTwitter className=" icon" /></a>}
                {speaker.youtubeProfile && <a className=" icon" href={speaker.youtubeProfile}><FaYoutube className=" icon" /></a>}



              </div>
            </Col> */}
          </Row>
          {/* <Row>
            <Col className="mx-3 mt-2"><h6 className="bold">Session(s)</h6></Col>
          </Row> */}
          {generalFeatureAllowed("agenda") &&
            <AgendaForSpeaker id={speaker._id} sessions={speaker.speakerSessions} />}
        </div>
      </Col >

      <MyVerticallyCenteredModal
        show={screen.modalShow}
        id={speaker._id}
        speaker={speaker}
        firstName={speaker.firstName}
        onHide={() => setScreen((prevState) => ({
          ...prevState,
          modalShow: false,
        }))}
      />
    </>
  );
};
